
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '../../../utilities/Types/navigation.types';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';
import router from '@/router';
import { AbortHandler } from '@/services/AbortHandler';
import { Status } from '@/enums/Status';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    await this.resetPaginationState();
    const response = await this.fetchMarketingCampaigns();
    this.total = response?.total;
    if (this.user?.brands && this.user?.brands?.length > 0) {
      await this.loadMarketingCampaignTags({ brandIds: this.user?.brands?.map((brand: any) => brand.brandId) });
    }

    if (this.$route.query.campaignId) {
      this.updateModalComponent('marketing-campaign');
      this.updateModalType('large');
      this.updateModalData({ campaignId: this.$route.query.campaignId, mode: 'edit' });
      this.openModal();
    }
  },
  watch: {
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchMarketingCampaigns();
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchMarketingCampaigns();
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchMarketingCampaigns();
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchMarketingCampaigns();
        this.total = response?.total;
      },
    },
    statusFilterValue: {
      deep: true,
      async handler() {
        await this.updatePaginationStatus(this.statusFilterValue.value as number);
        const response = await this.fetchMarketingCampaigns();
        this.total = response?.total;
      },
    },
    tagValues: {
      deep: true,
      async handler() {
        await this.updatePaginationTags(this.tagValues.map((tag) => tag.value));
        const response = await this.fetchMarketingCampaigns();
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['marketingCampaigns', 'loadingMarketingCampaigns', 'user', 'access', 'permissions', 'marketingCampaignTagOptions']),
    isLoading(): boolean {
      return this.loading || this.loadingMarketingCampaigns;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.permissions && this.permissions?.superAdminAccess) {
        return [
          {
            type: 'text',
            columnName: 'campaignNumber',
            displayName: 'Campaign ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'commSubTypeText',
            displayName: 'Type',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'brandText',
            displayName: 'Brand',
            sortable: true,
          },
          {
            type: 'stringArray',
            columnName: 'tagDescriptions',
            displayName: 'Campaign Tags',
          },
          {
            type: 'text',
            columnName: 'statusText',
            displayName: 'Status',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            sortable: true,
          },
          {
            type: 'image',
            columnName: 'imageUrl',
            displayName: 'Image',
            position: 'center',
          },
          {
            type: 'date',
            columnName: 'fromDate',
            displayName: 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
            sortable: true,
          },
          {
            type: 'approval',
            columnName: 'stats',
            displayName: 'Approval',
            headerStyle: 'min-width: 525px',
          },
          {
            type: 'text',
            columnName: 'marketingCampaignId',
            displayName: 'UUID',
            sortable: true,
          },
        ];
      } else {
        return [
          {
            type: 'text',
            columnName: 'campaignNumber',
            displayName: 'Campaign ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'commSubTypeText',
            displayName: 'Type',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'brandText',
            displayName: 'Brand',
            sortable: true,
          },
          {
            type: 'stringArray',
            columnName: 'tagDescriptions',
            displayName: 'Campaign Tags',
          },
          {
            type: 'text',
            columnName: 'statusText',
            displayName: 'Status',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Name',
            sortable: true,
          },
          {
            type: 'image',
            columnName: 'imageUrl',
            displayName: 'Image',
            position: 'center',
          },
          {
            type: 'date',
            columnName: 'fromDate',
            displayName: 'Start Date',
            sortable: true,
          },
          {
            type: 'date',
            columnName: 'toDate',
            displayName: 'End Date',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'timezone',
            displayName: 'Timezone',
            sortable: true,
          },
          {
            type: 'approval',
            columnName: 'stats',
            displayName: 'Approval',
            headerStyle: 'min-width: 525px',
          },
        ];
      }
    },
    tableData(): unknown {
      this.finishLoading();
      return this.marketingCampaigns;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    tabs(): Tabs {
      const t: any[] = [];
      if (this.access && !this.access?.marketingCampaignsNone) {
        t.push({
          title: 'MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/communications/marketing-campaigns',
        });
      }
      if (this.access && !this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/communications/recruitment-campaigns',
        });
      }
      if (this.access && !this.access?.mallMemosNone) {
        t.push({
          title: 'MALL MEMOS',
          route: 'mall-memos',
          activeRouteCheck: '/communications/mall-memos',
        });
      }
      if (this.access && !this.access?.storeMemosNone) {
        t.push({
          title: 'STORE MEMOS',
          route: 'store-memos',
          activeRouteCheck: '/communications/store-memos',
        });
      }
      if (this.access?.marketingCampaignsNone) {
        t.push({
          title: 'MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/communications/marketing-campaigns',
        });
      }
      if (this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/communications/recruitment-campaigns',
        });
      }
      if (this.access?.mallMemosNone) {
        t.push({
          title: 'MALL MEMOS',
          route: 'mall-memos',
          activeRouteCheck: '/communications/mall-memos',
        });
      }
      if (this.access?.storeMemosNone) {
        t.push({
          title: 'STORE MEMOS',
          route: 'store-memos',
          activeRouteCheck: '/communications/store-memos',
        });
      }
      return t;
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    tagValues: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      statusFilterValue: { description: 'All', value: 0 },
      statusFilterOptions: [
        { description: 'All', value: 0 },
        { description: 'Active', value: Status.Active },
        { description: 'Upcoming', value: Status.Upcoming },
        { description: 'Expired', value: Status.Expired },
        { description: 'Draft', value: Status.Draft },
        { description: 'Manually Expired', value: Status['Manually Expired'] },
      ],
      tagValues: [],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchMarketingCampaigns',
      'clearSelectedRecruitmentCampaign',
      'clearSelectedMarketingCampaign',
      'clearSelectedMallMemo',
      'clearSelectedStoreMemo',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'updatePaginationStatus',
      'updatePaginationTags',
      'resetPaginationState',
      'loadMarketingCampaignTags',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    updateTagsFilter(filter: any) {
      this.resetPageValue();
      this.tagValues = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      const abortController = AbortHandler.getInstance();
      abortController.kill();

      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    create(): void {
      let type = 'select-communications';
      this.clearSelectedRecruitmentCampaign();
      this.clearSelectedMarketingCampaign();
      this.clearSelectedMallMemo();
      this.clearSelectedStoreMemo();
      this.updateModalData(null);
      this.updateModalComponent('select');
      this.updateModalType(type);
      this.openModal();
    },
    reports(): void {
      this.$router.push('/reports/marketing-campaigns');
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/marketing-campaigns`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
    },
    rowClicked(row: any): void {
      this.updateModalComponent(row.commType);
      this.updateModalType('large');
      this.updateModalData({ campaignId: row?.communicationId, row, mode: 'edit' });
      this.openModal();
      router.replace({ query: { campaignId: row?.communicationId } });
    },
    approvalClicked(payload: { status: string; type: string; row: any }): void {
      this.updateModalComponent('approval');
      this.updateModalType('large');
      this.updateModalData(payload);
      this.openModal();
    },
  },
});
